<template>
  <div class="warpper">
    <div class="product">
      <div class="left-menu">
        <Menu
          width="auto"
          @on-select="cut"
          :active-name="currentName"
          :open-names="openedSubmenu"
          ref="menu"
        >
          <template v-for="item in bioList">
            <MenuItem
              v-if="!item.childList || item.childList.length < 1"
              :key="item.id"
              :name="item.keyword"
            >
              <span style="font-weight:bold">{{ item.name }}</span>
            </MenuItem>
            <Submenu v-else :key="item.id" :name="item.keyword">
              <template slot="title">
                <span style="font-weight:bold">{{ item.name }}</span>
              </template>
              <template v-for="el in item.childList">
                <MenuItem :key="el.id" :name="el.keyword" style="padding-left:15px">
                  <span>●&nbsp;&nbsp;{{ el.name }}</span>
                </MenuItem>
              </template>
            </Submenu>
          </template>
        </Menu>
      </div>
      <ul class="cont">
        <li v-for="(item, index) in list">
          <a @click="goThere(item)">
            <img :src="item.servicePath + item.cover" />
            <div class="product-name">
              <p :title="item.brief">{{ item.brief }}</p>
              <span>more</span>
            </div>
          </a>
        </li>
      </ul>
      <div class="clears"></div>
    </div>
    <div style="text-align: center" v-if="list.length > 0">
      <page-view :page="page" @pageChange="pageChange"></page-view>
    </div>
  </div>
</template>

<script>
import pageView from "@components/pageView";
export default {
  name: "",
  components: {
    pageView,
  },
  data() {
    return {
      bioList: [],
      list: [],
      //分页
      page: {
        pageNo: 1,
        pageSize: 9,
        total: 0,
      },

      currentName: "",
      openedSubmenu: [],
    };
  },
  computed: {
    getSubMenu() {
      return this.$store.state.permission.subMenu;
    },
    getCurrentRoute() {
      return this.$store.state.permission.currentRoute;
    },
  },
  watch: {
    getSubMenu: {
      deep: true,
      handler() {
        this.getList();
      },
    },
    currentName() {
      this.getData();
    },
  },
  methods: {
    getList() {
      if (Object.keys(this.getSubMenu).length == 0) return;
      let child = this.getSubMenu.childList.filter(
        (item) => item.router == this.getCurrentRoute
      )[0];
      if (child.Interface) {
        this.$post(child.Interface).then((res) => {
          this.bioList = res;
          // this.currentName = res[0]?.keyword ?? "";
          this.currentName = 'solidState'
          this.openedSubmenu = [res.find(item => item.keyword == 'mid').keyword]
          this.$nextTick(() => {
            this.$refs.menu.updateOpened();
            this.$refs.menu.updateActiveName();
          });
          this.getData();
        });
      }
    },
    getData() {
      this.$post("websitenews/list/" + this.currentName, {
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
      }).then((res) => {
        this.list = res.list || [];
        this.page.total = +res.total;
        document.body.scrollTop = document.documentElement.scrollTop = 0;
      });
    },
    cut(item) {
      this.currentName = item;
      this.page.pageNo = 1
    },
    childCut(item) {
      this.currentChildType = item;
    },
    //分页
    pageChange(pageNo) {
      this.page.pageNo = pageNo;
      this.getData();
    },
    goThere(item) {
      this.$router.push({
        name: "newsInfoTwo",
        query: {
          id: item.id,
          key: this.$route.name,
          keyword: this.currentName
        },
      });
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style lang="less" scoped>
@import url("./index.less");
.left-menu {
  /deep/ .ivu-menu {
    .ivu-menu-submenu {
      .ivu-menu-item {
        width: 70%;
        margin-left: 15%;
      }
    }
    .ivu-menu-item {
      padding: 7px 24px;
    }
    .ivu-menu-item-active:not(.ivu-menu-submenu) {
      color: #fff !important;
      background: #ff9c00;
    }
    .ivu-menu-item:hover,
    .ivu-menu-submenu-title:hover {
      color: #ff9c00;
    }
    > li {
      /* height: 50px;
      line-height: 50px;
      padding-top: 0;
      padding-bottom: 0; */
      color: #333;
      border-radius: 32px;
      .ivu-menu-submenu-title-icon {
        right: 5px;
      }
      span {
        letter-spacing: 1px;
      }
    }
  }
}
</style>